.ContentBlock {
  position: relative;
  color: var(--color-neutral-1000-light);
  display: flex;
  flex-direction: column;

  @include media-breakpoint-down(sm) {
    flex-direction: row;
  }

  &__body {

    @include media-breakpoint-up(md) {
      margin: 0 auto;

      .tmt-large & {
        width: calc(672px + (var(--space-md-large) * 2));
        padding: var(--space-xl-large) var(--space-md-large);
      }
    }

    &-inner {
      display: flex;
      flex-direction: column;
      align-items: center;
      & > * {
        margin-bottom: var(--space-md);
        text-align: center;

        .tmt-large & {
          margin-bottom: var(--space-md-large);
        }

        &:last-child {
          margin-bottom: 0;
        }

      }
      Button {
        left: 50%;
        transform: translate(-50%, 0);
        align-self: baseline;
      }
    }

  }

  &__sidebar {
    padding: var(--space-sm) var(--space-md);

    .tmt-large & {
      padding: var(--space-sm-large) var(--space-md-large);
    }

    @include media-breakpoint-up(md) {
      flex-basis: 468px;
      padding: var(--space-xl) var(--space-md);

      .tmt-large & {
        padding: var(--space-xl-large) var(--space-md-large);
      }
    }

    &-title {
      @include responsive-type("xs", "default", true);
      margin-bottom: var(--space-sm);

      .tmt-large & {
        @include responsive-type("xs", "large", true);
        margin-bottom: var(--space-sm-large);
      }
    }

    &-inner {
      & > * {
        margin-bottom: var(--space-md);

        .tmt-large & {
          margin-bottom: var(--space-md-large);
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
      .direction {
        flex-direction: column;
      }
    }
  }
}
 .ContentBlock.sidebar {
  flex-direction: row;
 }

 .faq {
    .accordion-item:not(:first-of-type) {
      margin-top: var(--space-md)!important;
    }
}

.Carousel {
  position: relative;

  &__wrapper {
    display: flex;
    align-items: flex-start;

    &::after {
      content: '';
      width: 100%;

      .Carousel--customRatio & {
        padding-top: var(--ratio);
      }
    }
  }

  &__slide {
    max-width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    padding: 0px 16px;
  }

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    .Carousel--light & {
      &::after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: var(--backdrop-gradient-light);
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__title {
    padding-top: var(--space-sm);
    padding-bottom: var(--space-sm);
    width: 100%;

    .Card {

      .Carousel--noNav & {
        margin: 0;
      }

      .CardTitle__title {
        @include responsive-type(md, "default", true);

        .Carousel--light & {
          color: var(--color-white-light);
        }
      }

      .CardBody {

        & > * {
          margin-bottom: var(--space-sm);

          &:last-child {
            margin-bottom: 0;
          }
        }

        .Carousel--light & {
          color: var(--color-white-light);
        }
      }

      .tmt-large &,
      .Carousel--large & {

        .CardBody > * {
          margin-bottom: var(--space-sm-large);

          &:last-child {
            margin-bottom: 0;
          }
        }

        .CardTitle__title {
          @include responsive-type(md, "large", true);
        }
      }
    }
  }

  .Control {
    display: none;

    @include media-breakpoint-up(xs) {
      width: var(--space-lg);
      height: var(--space-lg);
      display: inline-flex;
    }

    @include media-breakpoint-up(xl) {
      width: var(--space-xl);
      height: var(--space-xl);
    }
  }

  &__nav-pagination {
    display: flex;
    justify-content: center;
  }

  &__nav-prev, &__nav-next {
    position: relative;
    z-index: 2;
    bottom: 13px !important; // We need to overwrite default swiper
  }

  &__nav-gallery {
    position: relative;
    display: flex;
    justify-content: center;
    z-index: 1;
  }

  &__pagination {
    display: none;
    position: relative;
    bottom: var(--space-sm) !important; // We need to overwrite default swiper

    @include media-breakpoint-up(md) {
      bottom: var(--space-md) !important; // We need to overwrite default swiper
    }

    @include media-breakpoint-up(xl) {
      bottom: var(--space-xl) !important; // We need to overwrite default swiper
    }

    .tmt-large &,
    .Carousel--large & {
      bottom: var(--space-sm-large) !important;

      @include media-breakpoint-up(md) {
        bottom: var(--space-md-large) !important;
      }

      @include media-breakpoint-up(xl) {
        bottom: var(--space-xl-large) !important;
      }
    }

    .swiper-pagination-bullet {
      width: var(--space-sm);
      height: var(--space-sm);
      background: none;
      border: 2px solid var(--color-white-light);
      opacity: 1;
      margin: 4px !important;
      margin-right: 8px !important;
      transition: background .15s linear;

      &:last-child {
        margin-right: 4px !important;
      }

      &:hover,
      &-active {
        background: var(--color-white-light);
      }

      .Carousel--light & {
        border: 2px solid var(--color-white-light);

        &:hover,
        &-active {
          background: var(--color-white-light);
        }
      }
    }
  }
}

.ImageGrid {

  @include media-breakpoint-down(lg) {
    padding-bottom: 56px;
  }

  grid-column:1 / 3;
  order:2;
  @include media-breakpoint-down(lg) {
    .info {
      display:none;
    }
  }
  @include media-breakpoint-up(lg) {
    order:3;
    .swiper-wrapper {
      display:grid;
      grid-column-gap:var(--space-md);
    }
  }
  &.grid-2 {
    @include media-breakpoint-up(lg) {
      .swiper-wrapper {
        grid-template-columns: repeat(2, 1fr);
        transform:none!important;
        .swiper-slide {
          width:100%!important;
        }
      }
    }
  }
  &.grid-3 {
    @include media-breakpoint-up(lg) {
      .swiper-wrapper {
        grid-template-columns: repeat(3, 1fr);
        transform:none!important;
        .swiper-slide {
          width:100%!important;
        }
      }
    }
  }
  &.grid-4 {
    @include media-breakpoint-up(lg) {
      .swiper-wrapper {
        grid-template-columns: repeat(2, 1fr);
        transform:none!important;
        .swiper-slide {
          width:100%!important;
        }
      }
    }
    @include media-breakpoint-up(xl) {
      .swiper-wrapper {
        grid-template-columns: repeat(4, 1fr);
        transform:none!important;
        .swiper-slide {
          width:100%!important;
        }
      }
    }
  }
  &.grid-5 {
    @include media-breakpoint-up(lg) {
      .swiper-wrapper {
        grid-template-columns: repeat(4, 1fr);
        grid-row-gap: var(--space-md);
        transform:none!important;
        .swiper-slide {
          width:100%!important;
        }
      }
    }
  }
}

.swiper-button-disabled {
  opacity: 0.5;
}

.hide-nav {
  @include media-breakpoint-up(lg) {
  display: none;
  }
}

.swiper-button-disabled {
  .arrow {
    cursor: not-allowed !important;
    &:hover {
      color: var(--color-neutral-500-light)!important;
    }
  }
}

@import "item";

.ImageGallery {
  position: relative;
  display: grid;
  column-gap: var(--space-md);
  grid-row-gap: var(--space-md);
  grid-template-columns: repeat(1, minmax(0, 1fr));

  .tmt-large &,
  &--large {
    grid-row-gap: var(--space-md-large);
  }
}

.lg {

  &-close.lg-icon {
    &::after {
      content: '';
      display: block;
      width: 24px;
      height: 24px;
      background: url('data:image/svg+xml; utf8, <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <path fill-rule="evenodd" clip-rule="evenodd" d="M3.29289 3.29289C3.68342 2.90237 4.31658 2.90237 4.70711 3.29289L12 10.5858L19.2929 3.29289C19.6834 2.90237 20.3166 2.90237 20.7071 3.29289C21.0976 3.68342 21.0976 4.31658 20.7071 4.70711L13.4142 12L20.7071 19.2929C21.0976 19.6834 21.0976 20.3166 20.7071 20.7071C20.3166 21.0976 19.6834 21.0976 19.2929 20.7071L12 13.4142L4.70711 20.7071C4.31658 21.0976 3.68342 21.0976 3.29289 20.7071C2.90237 20.3166 2.90237 19.6834 3.29289 19.2929L10.5858 12L3.29289 4.70711C2.90237 4.31658 2.90237 3.68342 3.29289 3.29289Z" fill="white"/> </svg>');
    }
  }

  &-backdrop {
    background-color: var(--color-neutral-900-light);
  }

  &-sub-html,
  &-toolbar {
    background: rgb(0, 0, 0, 0);
  }

  &-outer {
    .lg-img-wrap {
      height: calc(100% - 48px);
    }
  }

  &-actions {
    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  &-prev,
  &-next {
    transform: translateY(-24px);
  }
}

@include media-breakpoint-down(sm) {
  .lg-actions {
    display: block;
    position: relative;
    top: 26px;
    z-index: 2000;
    .lg-next {
      left: 20px;
      margin-left: 50px;
    }
  }
}

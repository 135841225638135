$radii: (
  0: 0,
  2: 2px,
  4: 4px,
  8: 8px,
  12: 12px,
  16: 16px,
  32: 32px,
  48: 48px
);

:root {
  @each $prop, $value in $radii {
    --border-radius-#{$prop}: #{$value}
  }
}

@each $prop, $value in $radii {
  .border-radius-#{$prop} {
    border-radius: #{$value} !important;
  }
}

// .range-slider {
//     height: 5px;
//     position: relative;
//     background-color: #e1e9f6;
//     border-radius: 2px;
//   }
//   .range-selected {
//     height: 100%;
//     left: 30%;
//     right: 30%;
//     position: absolute;
//     border-radius: 5px;
//     background-color: #1b53c0;
//   }
//   .range-input {
//     position: relative;
//   }
//   .range-input input {
//     position: absolute;
//     width: 100%;
//     height: 5px;
//     top: -10px;
//     background: none;
//     pointer-events: none;
//     border: none;
//     -webkit-appearance: none;
//     -moz-appearance: none;
//   }
//   .range-input input::-webkit-slider-thumb {
//     height: 20px;
//     width: 20px;
//     border-radius: 50%;
//     border: 3px solid #1b53c0;
//     background-color: #1b53c0;
//     pointer-events: auto;
//     -webkit-appearance: none;
//   }
//   .range-input input::-moz-range-thumb {
//     height: 15px;
//     width: 15px;
//     border-radius: 50%;
//     border: 3px solid #1b53c0;
//     background-color: #1b53c0;
//     pointer-events: auto;
//     -moz-appearance: none;
//   }
//   .range-price {
//     margin: 30px 0;
//     width: 100%;
//     display: flex;
//     justify-content: flex-end;
//     align-items: center;
//   }
//   .range-price label {
//     margin-right: 5px;
//   }
//   .range-price input {
//     width: 50px;
//     padding: 5px;
//     border: none;
//   }
//   .range-price input:first-of-type {
//     margin-right: 15px;
//   }

#slider {
  height: 2px;
}

#slider .noUi-connect {
  background: var(--color-primary-500-light);
}

#slider .noUi-handle {
  height: 18px;
  width: 18px;
  top: -9px;
  right: -9px; /* half the width */
  border-radius: 9px;
  box-shadow: none;
  background: var(--color-primary-500-light);
}

.noUi-handle:before, .noUi-handle:after {
  background: none!important;
}

.range-price {
  margin: 30px 0;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.range-price label {
  margin-right: 5px;
}
.range-price input {
  width: 50px;
  padding: 5px;
  border: none;
}
.range-price input:first-of-type {
  margin-right: 15px;
}

html,
body {
  position: relative;
  max-width: 100%;
  // overflow-x: hidden;
  height: auto;
}

.noscroll {
  overflow: hidden;
}

body {
  margin: 0;
  padding: 0;
  @include main-font;
  @include responsive-type("baseline");
  font-style: normal;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
}

// header, main, footer {
//   max-width: 1920px;
//   margin-left: auto;
//   margin-right: auto;
// }

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

section {
  padding: var(--space-xl) var(--space-md);
  @include media-breakpoint-down(md) {
    padding: var(--space-lg) var(--space-sm);
  }
}

.heading2Style {
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
}

.heading3Style {
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
}

.heading4Style {
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
}

a {
  color: inherit;
  font-weight: 400;

  &:hover,
  &:focus {
    color: inherit;
    text-decoration: none;
    outline: none;
  }
  &:visited {
    color: unset;
  }
}

input,
textarea,
select,
button {
  color: inherit;
}

input,
button {
  &:focus {
    outline: none;
  }
}

.flex-images {
  display: flex;
  gap: 16px;
}

.error-404-500 {
  font-size: 64px;
  line-height: 72px;
  font-weight: bold;
  color: var(--color-primary-500-light);
}

label {
  display: block;
  @include responsive-type("tiny");
  color: var(--color-neutral-500-light);
  margin-bottom: var(--space-tiny);

  .tmt-large & {
    @include responsive-type("tiny", "large");
  }
}

svg {
  display: inline-block!important;
}

.bg-white {
  background-color: white!important;
}

.hover\:bg-neutral-100:hover {
  background-color: var(--color-neutral-100-light)!important;
}

.border {
  border: inherit !important;
  border-width: 1px !important;
}

.border-neutral-300 {
  border-color: var(--color-neutral-300-light)!important;
}

.fill-current {
  fill: white !important;
}

.text-white {
  color: white !important;
}

@for $i from 3 through 10 {
  .temp-presentation-grid-x#{$i} {
    display: inline-grid;
    align-items: center;
    grid-auto-flow: column;
    grid-template-rows: repeat(#{$i}, auto);
    grid-gap: 32px;
  }
}

/* Customize website's scrollbar like Mac OS
Not supports in Firefox and IE */

/* total width */
.choices__list::-webkit-scrollbar {
  background-color: #fff;
  width: 16px;
}

/* background of the scrollbar except button or resizer */
.choices__list::-webkit-scrollbar-track {
  background-color: #fff;
}

.choices__list::-webkit-scrollbar-track:hover {
  background-color: #f4f4f4;
}

/* scrollbar itself */
.choices__list::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 16px;
  border: 5px solid #fff;
}

.choices__list::-webkit-scrollbar-thumb:hover {
  background-color: #a0a0a5;
  border: 4px solid #f4f4f4;
}

/* set button(top and bottom of the scrollbar) */
.choices__list::-webkit-scrollbar-button {
  display: none;
}

.tooltip {
  filter: drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.16));

  &-inner {
    @include responsive-type("small");

    .tmt-large & {
      @include responsive-type("small", "large");
    }
  }
}

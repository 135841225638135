.direction {
    flex-direction: row;
    @include media-breakpoint-down(sm) {
        flex-direction: column;
      }
}

.vertical {
    flex-direction: column!important;
}

.block-heading {
    align-items: center;
    margin-bottom: var(--space-sm);
    @include media-breakpoint-down(sm) {
        align-items: flex-start;
      }
}

.heading-title {
  font-weight: 600;
    @include media-breakpoint-down(sm) {
        margin-bottom: var(--space-sm);
      }
}

.pt-artists-list {
	overflow: hidden;
  .CardBody {
    padding: 0;
  }
  .CardTitle {
    margin-bottom: var(--space-sm);
  }
  .alphabet {
    position: relative;
    list-style: none;
    margin-left: 0;
    padding-left: 0;
    white-space: nowrap;
    overflow-x: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar{
      display: none;
    }
  
    li {
      display: inline-block;
      margin-right: 20px;
      a {
        font-weight: bold;
        color: var(--color-primary-500-light);
      }
    }
  }
  
  .left-gradient {
    position: absolute;
    width: 48px;
    left: 0px;
    top: 1px;
    bottom: 1px;
    background: linear-gradient(90deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
  }
  
  .right-gradient {
    position: absolute;
    width: 48px;
    right: 0px;
    top: 1px;
    bottom: 1px;
    background: linear-gradient(270deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
    }
  
  .pt-artists-list-letter {
    padding-top: var(--space-lg-large);
    padding-bottom: var(--space-sm);
  }
  
  .items-grid {
    column-count: 4;
    @include media-breakpoint-down(md) {
      column-count: 2;
    }
    @include media-breakpoint-down(sm) {
      column-count: 1;
    }
  }
  
  .pt-artists-items {
    font-weight: 590;
    padding-bottom: 8px;
    overflow-x: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar{
      display: none;
    }
  }
  
  .lang-toggle {
    padding-left: 12px;
    border-left: 1px solid var(--color-neutral-300-light);
    &:hover {
      cursor: pointer;
    }
  }
  
  .lang-gr{
    display: none;
  }
}

@include media-breakpoint-down(md) {
  .CardBody {
    flex-direction: column !important;
  }
}

.custom-brown-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 40px;
  background-color: var(--color-secondary-500-light);
  padding: 96px 0;
  overflow: hidden;
  gap: var(--space-xl);
  .custom-heading {
    gap: var(--space-ms);

    @include media-breakpoint-down(md) {
      gap: var(--space-sm);
    }

    .heading-title:first-child,
    .heading-title:last-child {
      opacity: 10%;
    }
    .heading-title:nth-child(2),
    .heading-title:nth-child(8) {
      opacity: 30%;
    }
    .heading-title:nth-child(3),
    .heading-title:nth-child(7) {
      opacity: 50%;
    }
    .heading-title:nth-child(4),
    .heading-title:nth-child(6) {
      opacity: 50%;
    }
  }

  .custom-images {
    gap: var(--space-ms);
    justify-content: center;

    @include media-breakpoint-down(md) {
      gap: var(--space-sm);
    }

    .blob-mask {
      position: relative;

      &:hover .overlay {
        opacity: 1;
      }

      .overlay {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: 100%;
        width: 100%;
        background: black;
        opacity: 0;
        transition: 0.5s ease;
        background-color: var(--backdrop-black-48-light);

        .blob-title {
          display: block;
        }
      }

      @include media-breakpoint-down(md) {
        width: 191px;
        height: 206px;
      }

      .blob-title {
        display: none;
        position: absolute;
        width: 90%;
        left: 0px;
        text-align: center;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: var(--color-white-light);
        z-index: 2;
        font-weight: 700;
        font-size: 20px;
        line-height: 28px;
      }
    }

    .mask1 {
      -webkit-mask-image: url("/wp-content/themes/toolstrap/public/images/mask1.png");
      mask-image: url("/wp-content/themes/toolstrap/public/images/mask1.png");
      -webkit-mask-repeat: no-repeat;
      mask-repeat: no-repeat;

      @include media-breakpoint-down(md) {
        -webkit-mask-image: url("/wp-content/themes/toolstrap/public/images/mask1-mob.png");
        mask-image: url("/wp-content/themes/toolstrap/public/images/mask1-mob.png");
      }
    }

    .mask2 {
      -webkit-mask-image: url("/wp-content/themes/toolstrap/public/images/mask2.png");
      mask-image: url("/wp-content/themes/toolstrap/public/images/mask2.png");
      -webkit-mask-repeat: no-repeat;
      mask-repeat: no-repeat;

      @include media-breakpoint-down(md) {
        -webkit-mask-image: url("/wp-content/themes/toolstrap/public/images/mask2-mob.png");
        mask-image: url("/wp-content/themes/toolstrap/public/images/mask2-mob.png");
      }
    }

    .mask3 {
      -webkit-mask-image: url("/wp-content/themes/toolstrap/public/images/mask3.png");
      mask-image: url("/wp-content/themes/toolstrap/public/images/mask3.png");
      -webkit-mask-repeat: no-repeat;
      mask-repeat: no-repeat;

      @include media-breakpoint-down(md) {
        -webkit-mask-image: url("/wp-content/themes/toolstrap/public/images/mask3-mob.png");
        mask-image: url("/wp-content/themes/toolstrap/public/images/mask3-mob.png");
      }
    }

    .mask4 {
      -webkit-mask-image: url("/wp-content/themes/toolstrap/public/images/mask4.png");
      mask-image: url("/wp-content/themes/toolstrap/public/images/mask4.png");
      -webkit-mask-repeat: no-repeat;
      mask-repeat: no-repeat;

      @include media-breakpoint-down(md) {
        -webkit-mask-image: url("/wp-content/themes/toolstrap/public/images/mask4-mob.png");
        mask-image: url("/wp-content/themes/toolstrap/public/images/mask4-mob.png");
      }
    }

    .mask5 {
      -webkit-mask-image: url("/wp-content/themes/toolstrap/public/images/mask5.png");
      mask-image: url("wp-content/themes/toolstrap/public/images/mask5.png");
      -webkit-mask-repeat: no-repeat;
      mask-repeat: no-repeat;

      @include media-breakpoint-down(md) {
        -webkit-mask-image: url("/wp-content/themes/toolstrap/public/images/mask5-mob.png");
        mask-image: url("/wp-content/themes/toolstrap/public/images/mask5-mob.png");
      }
    }

    .responsive-image {
      width: 344px;
      height: 344px;

      @include media-breakpoint-down(md) {
        width: 191px;
        height: 206px;
      }
    }
  }
  .custom-text {
    max-width: 714px;
    text-align: center;
  }
}

.Pagination {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  &__arrow {
    width: 40px;
    height: 40px;
    background-color: rgba(0, 0, 0, 0);
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;

    .tmt-large & {
      width: 52px;
      height: 52px;
    }

    &--inactive {
      cursor: not-allowed !important;
    }
  }

  &__input {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border-color: transparent;
    @include responsive-type("baseline");
    font-weight: 700;
    text-align: center;
    margin: 0 18px 0 18px;
    background-color: rgba(0, 0, 0, 0);

    &:hover,
    &:focus {
      &:not(:disabled) {
        background-color: var(--color-primary-700-light);
        color: var(--color-white-light);
      }
    }
  }
}

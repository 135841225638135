@use "sass:map";
@use "../../assets/sass/variables/colors";

$dropdown-icon-size: 24px;
$dropdown-icon-size-large: 28px;

.choices {
  color: var(--color-neutral-0-light);
  border: 1px solid var(--color-neutral-300-light);
  border-radius: 32px;
  margin-bottom: 0;
  @include media-breakpoint-up(xxl) {
  min-width: 150px;
  }

  &::after {
    filter: invert(58%) sepia(83%) saturate(0%) hue-rotate(294deg) brightness(94%) contrast(87%);
  }

  &:focus-visible,
  &:focus,
  &:active {
    border-color: var(--color-primary-500-light);
  }

  &:invalid {
    border-color: var(--color-red-500-light)
  }

  &:disabled {
    background-color: var(--color-neutral-100-light);
  }

  &[data-type*=select-multiple] &__inner {
    padding-right: calc((var(--space-xxs) * 2) + #{$dropdown-icon-size});

    .tmt-large & {
      padding-right: calc((var(--space-xxs-large) * 2) + #{$dropdown-icon-size-large});
    }
  }

  &__inner {
    min-height: unset;
    padding: var(--space-xxs);
    padding-bottom: var(--space-xxs) !important; // Stubborn default css override
    padding-left: var(--space-sm);
    background: none;
    border: none;

    .tmt-large & {
      padding: var(--space-xxs-large);
      padding-bottom: var(--space-xxs-large) !important; // Stubborn default css override
      padding-left: var(--space-sm-large);
    }
  }

  &__list {
    border: none;
    white-space: nowrap;
    text-align: left;

    &--single {
      padding: 0;
      padding-right: var(--space-ms);

      .tmt-large & {
        padding-right: var(--space-ms-large);
      }
    }

    &--multiple {
      .choices__item {
        &,
        &.is-highlighted {
          @include type("baseline");
          margin: 0;
          padding: 0;
          background-color: rgba(0,0,0,0);
          border: none;
          border-radius: var(--border-radius-4);
          color: var(--color-neutral-700-light);

          .tmt-large & {
            @include type("baseline", "large");
          }
        }
      }
    }
  }

  &__list--dropdown &__item,
  &__list[aria-expanded] &__item,
  &__item {
    @include type("baseline");
    color: var(--color-neutral-700-light);

    .tmt-large & {
      @include type("baseline", "large");
    }

    .choices__list--multiple & {
      &::after {
        content: ", ";
        display: inline-block;
        margin-right: var(--space-xxs);
      }
    }
  }

  &__list[aria-expanded],
  &__list--dropdown {
    // min-width: 300px;
    &,
    .is-open & {
      border: 1px solid var(--color-neutral-300-light);
      border-radius: var(--border-radius-4);
    }

    .choices__item {
      padding-left: var(--space-sm);

      &--separated {
        border-bottom: 1px solid var(--color-neutral-300-light);
      }

      .tmt-large & {
        padding-left: var(--space-sm-large);
      }
    }
  }

  &__list--dropdown &__item--selectable.is-highlighted,
  &__list[aria-expanded] &__item--selectable.is-highlighted {
    background: var(--color-neutral-100-light);
  }

  &__list--dropdown &__item--selectable.is-selected:not(.choices__placeholder),
  &__list[aria-expanded] &__item--selectable.is-selected:not(.choices__placeholder) {
    &:not(.choices__list[aria-multiselectable="true"] .choices__item) {
      background: rgba(0, 0, 0, 0);
      display: flex;

      &::before {
        content: "";
        display: inline-block;
        order: 2;
        width: $dropdown-icon-size;
        height: $dropdown-icon-size;
        margin-left: var(--space-xxs);
        background-image: url('data:image/svg+xml; utf8, <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <path fill-rule="evenodd" clip-rule="evenodd" d="M20.7399 6.32733C21.1115 6.73599 21.0813 7.36844 20.6727 7.73995L9.67268 17.7399C9.29126 18.0867 8.70876 18.0867 8.32733 17.7399L3.32733 13.1945C2.91868 12.823 2.88856 12.1905 3.26007 11.7819C3.63157 11.3732 4.26402 11.3431 4.67268 11.7146L9.00001 15.6485L19.3273 6.26007C19.736 5.88856 20.3684 5.91868 20.7399 6.32733Z" fill="#{rgba(map.get(colors.$colors, 'primary-500', 'light'), .99)}"/> </svg>');
        background-size: contain;
        background-repeat: no-repeat;

        .tmt-large & {
          width: $dropdown-icon-size-large;
          height: $dropdown-icon-size-large;
          margin-left: var(--space-xxs-large);
        }
      }
    }
  }

  &__list[aria-multiselectable="true"] &__item {

    &::before {
      content: "";
      display: inline-block;
      width: $dropdown-icon-size;
      height: $dropdown-icon-size;
      border-radius: var(--border-radius-4);
      border: 1px solid var(--color-neutral-300-light);
      transform: translateY(5px);
      margin-right: var(--space-xxs);

      .tmt-large & {
        width: $dropdown-icon-size-large;
        height: $dropdown-icon-size-large;
      }
    }

    &.is-selected {
      &::before {
        background: var(--color-primary-500-light);
        background-image: url('data:image/svg+xml; utf8, <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M20.74 6.327a1 1 0 01-.067 1.413l-11 10a1 1 0 01-1.346 0l-5-4.545a1 1 0 011.346-1.48L9 15.649 19.327 6.26a1 1 0 011.413.067z" fill="white"/></svg>');
        background-position: center center;
        border: 1px solid var(--color-primary-500-light);
      }
    }
  }

  input[type="search"].choices__input::-webkit-input-placeholder,
  input.choices__input::-webkit-input-placeholder,
  input[type="search"].choices__input {

    @include type("baseline");
    padding: 0;
    border: 0;
    background-image: none;
    margin-bottom: 0;
    background: none;

    .tmt-large & {
      @include type("baseline", "large");
    }
  }

  &, &.is-open {
    &[data-type*=select-multiple]::after,
    &[data-type*=select-one]::after {
      content: "";
      position: absolute;
      background: url('data:image/svg+xml; utf8, <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M12.354 5.354a.5.5 0 00-.708 0L7.853 9.146a.5.5 0 00.354.854h7.586a.5.5 0 00.353-.854l-3.793-3.792zm0 13.292a.5.5 0 01-.708 0l-3.793-3.792A.5.5 0 018.207 14h7.586a.5.5 0 01.353.854l-3.793 3.792z" fill="currentColor"/></svg>');
      background-position: center center;
      border: none;
      width: #{$dropdown-icon-size};
      height: #{$dropdown-icon-size};
      right: var(--space-xxs);
      top: 50%;
      transform: translateY(-50%);
      margin-top: 0px;

      .tmt-large & {
        width: #{$dropdown-icon-size-large};
        height: #{$dropdown-icon-size-large};
        right: var(--space-xxs-large);
      }
    }
  }
}

.compact {
  .choices {
    border: none;
    &:after {
      filter: invert(0%) sepia(89%) saturate(3%) hue-rotate(105deg) brightness(97%) contrast(100%);
    }
    &:focus-visible,
    &:focus,
    &:active {
      &:after {
        filter: invert(45%) sepia(85%) saturate(2811%) hue-rotate(194deg) brightness(99%) contrast(104%);
      }
    }
    &:invalid {
      &:after {
        filter: invert(24%) sepia(86%) saturate(6959%) hue-rotate(356deg) brightness(77%) contrast(119%);
      }
    }

    &__item{
      text-align: center;
      font-weight: 700;
      color: var(--color-neutral-1000-light);

    .choices__placeholder {
      @include responsive-type("baseline");

    .tmt-large & {
      @include responsive-type("baseline", "large");
    }
  }
    &:focus-visible,
    &:focus,
    &:active {
      color: var(--color-primary-500-light);
    }
    &:invalid {
      color: var(--color-red-500-light);
    }
  }
  .choices__button {
    display: none;
  }
  }
}

.choices[data-type*=select-one].is-open::after {
margin-top: 0;
}

.choices[data-type*=select-one] .choices__button {

  background-color: var(--color-neutral-0-light);
}


.choices[data-type*=select-one] .choices__button {
  opacity: 1;
  background-size: 24px;
  height: 24px;
  width: 22px;
  margin-top:-12px;
  margin-right: 15px;
  z-index: 10;
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21ZM8.70711 7.29289C8.31658 6.90237 7.68342 6.90237 7.29289 7.29289C6.90237 7.68342 6.90237 8.31658 7.29289 8.70711L10.5858 12L7.29289 15.2929C6.90237 15.6834 6.90237 16.3166 7.29289 16.7071C7.68342 17.0976 8.31658 17.0976 8.70711 16.7071L12 13.4142L15.2929 16.7071C15.6834 17.0976 16.3166 17.0976 16.7071 16.7071C17.0976 16.3166 17.0976 15.6834 16.7071 15.2929L13.4142 12L16.7071 8.70711C17.0976 8.31658 17.0976 7.68342 16.7071 7.29289C16.3166 6.90237 15.6834 6.90237 15.2929 7.29289L12 10.5858L8.70711 7.29289Z' fill='%23B67A3E'/%3E%3C/svg%3E%0A");
}

.tmt-large {
  .choices[data-type*=select-one] .choices__button {
    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21ZM8.70711 7.29289C8.31658 6.90237 7.68342 6.90237 7.29289 7.29289C6.90237 7.68342 6.90237 8.31658 7.29289 8.70711L10.5858 12L7.29289 15.2929C6.90237 15.6834 6.90237 16.3166 7.29289 16.7071C7.68342 17.0976 8.31658 17.0976 8.70711 16.7071L12 13.4142L15.2929 16.7071C15.6834 17.0976 16.3166 17.0976 16.7071 16.7071C17.0976 16.3166 17.0976 15.6834 16.7071 15.2929L13.4142 12L16.7071 8.70711C17.0976 8.31658 17.0976 7.68342 16.7071 7.29289C16.3166 6.90237 15.6834 6.90237 15.2929 7.29289L12 10.5858L8.70711 7.29289Z' fill='%23B67A3E'/%3E%3C/svg%3E%0A");
  }
}

.choices__list.choices__list--single {
.choices__item.choices__placeholder.choices__item--selectable {
  opacity: 1!important;
  }
}

.rich-content-block {
    display: flex;
    flex-direction: row;
    @include media-breakpoint-down(md) {
        flex-direction: column;
    }

    .block-heading {
        margin-bottom: var(--space-md);
    }
    .facts-container {
        display: grid;
        justify-content: space-between;
        grid-template-columns: repeat(4, 1fr);
        @include media-breakpoint-down(md) {
            grid-template-columns: repeat(2, 1fr);
        }
        @include media-breakpoint-down(sm) {
            grid-template-columns: repeat(1, 1fr);
        }
    }

    .rich-text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      @include media-breakpoint-down(md) {
        margin-top: var(--space-sm);
      }
    }

}

.right-alignment {
    .main-image {
        order: 2;
    }

    .rich-text {
        order: 1;
    }

    @include media-breakpoint-down(md) {
        .main-image {
            order: 1;
        }
        .rich-text {
            order: 2;
        }
    }
}

.banner-content {
    display: flex;
    align-items: center;
    @include media-breakpoint-down(md) {
        flex-direction: column;
        .banner-rich-text {
            order: 1;
            margin-bottom: var(--space-md);
        }
        .banner-image {
            order: 2;
            .AspectRatioBox {
              & > img {
              border-radius: var(--border-radius-8);
            }
          }
        }
    }
}

.banner-pattern-1 {
    background: url("/images/p4.png") repeat;
}

.banner-pattern-2 {
    background: url("/images/p5.png") repeat;
}

.banner-image {
    img {
    border-radius: var(--border-radius-8);
  }
}

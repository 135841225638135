.home-video {
  position: relative;
  height: 90vh;

  article {
    position: absolute;
    top: 0;
    padding: 0;
  }

  img, video {
    filter: brightness(80%);
    height:100% !important;
  }

  .hero-text {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 50%;
    color: var(--color-white-light);
    max-width: 714px;
    text-align: center;
    font-size: 32px !important;
    font-weight: 400 !important;

    @include media-breakpoint-down(md) {
      visibility: hidden;
    }
  }
}

.AspectRatioBox {
  position: relative;
  width: 100%;
  padding-top: var(--ratio);

  & > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  & > img {
    object-fit: cover;
  }
}

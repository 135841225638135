@use "sass:map";
@use "../../assets/sass/variables/colors";

$input-icon-size: 24px;
$input-icon-size-large: 28px;

form {
  position: relative;
}

input,
textarea {
  @include responsive-type("baseline");
  position: relative;
  width: 100%;
  color: var(--color-neutral-700-light);
  border: 1px solid var(--color-neutral-300-light);
  border-radius: 32px;
  padding: var(--space-xxs) var(--space-sm);


  &:focus-visible,
  &:focus,
  &:active {
    outline: 0;
    border: 1px solid var(--color-primary-500-light);
  }

  &:invalid {
    border: 1px solid var(--color-red-500-light);
    padding-right: calc((var(--space-sm) * 2) + #{$input-icon-size});
    background-size: $input-icon-size;
    background-position: center right var(--space-sm);
    background-repeat: no-repeat;

    &:not(textarea) {
      background-image: url('data:image/svg+xml; utf8, <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <path fill-rule="evenodd" clip-rule="evenodd" d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21ZM12 6C12.5523 6 13 6.44772 13 7V13C13 13.5523 12.5523 14 12 14C11.4477 14 11 13.5523 11 13V7C11 6.44772 11.4477 6 12 6ZM13 17C13 17.5523 12.5523 18 12 18C11.4477 18 11 17.5523 11 17C11 16.4477 11.4477 16 12 16C12.5523 16 13 16.4477 13 17Z" fill="#{rgba(map.get(colors.$colors, 'red-500', 'light'), .99)}"/> </svg>');
    }

    &::placeholder {
      color: var(--color-neutral-1000-light);
    }

    .tmt-large & {
      padding-right: calc((var(--space-sm-large) * 2) + #{$input-icon-size-large});
      background-size: $input-icon-size-large;
      background-position: center right var(--space-sm-large);
    }
  }

  &[type="search"] {
    padding-left: calc((var(--space-md)) + #{$input-icon-size});
    background-image: url('data:image/svg+xml; utf8, <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <path fill-rule="evenodd" clip-rule="evenodd" d="M11 17C7.68629 17 5 14.3137 5 11C5 7.68629 7.68629 5 11 5C14.3137 5 17 7.68629 17 11C17 14.3137 14.3137 17 11 17ZM3 11C3 6.58172 6.58172 3 11 3C15.4183 3 19 6.58172 19 11C19 12.8487 18.3729 14.551 17.3199 15.9056L21.7071 20.2929C22.0976 20.6834 22.0976 21.3166 21.7071 21.7071C21.3166 22.0976 20.6834 22.0976 20.2929 21.7071L15.9056 17.3199C14.551 18.3729 12.8487 19 11 19C6.58172 19 3 15.4183 3 11Z" fill="#{rgba(map.get(colors.$colors, 'neutral-500', 'light'), .99)}"/> </svg>');
    background-size: $input-icon-size $input-icon-size;
    background-position: center left 18px;
    background-repeat: no-repeat;
    background-color: transparent;

    .tmt-large & {
      padding-left: calc((var(--space-sm) * 2) + #{$input-icon-size-large});
      background-size: $input-icon-size;
      background-position: center left 18px;
    }
  }

  &[type="number"] {
    -moz-appearance: textfield;
    width: 120px;
    padding-left: calc((var(--space-xxs) * 2) + #{$input-icon-size});
    padding-right: calc((var(--space-xxs) * 2) + #{$input-icon-size});
    text-align: center;

    &:invalid {
      background-image: none;
    }

    .tmt-large & {
      width: 156px;
      padding-left: calc((var(--space-xxs-large) * 2) + #{$input-icon-size-large});
      padding-right: calc((var(--space-xxs-large) * 2) + #{$input-icon-size-large});
    }
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type="radio"],
  &[type="checkbox"] {
    width: auto;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    border: 0;
    padding: 0;

    &::before {
      content: "";
      display: block;
      width: #{$input-icon-size + 2px};
      height: #{$input-icon-size + 2px};
      border: 1px solid var(--color-neutral-300-light);
      border-radius: var(--border-radius-4);
    }

    &:focus-visible,
    &:focus,
    &:active {
      outline: 0;
      border: none;

      &::before {
        border-color: var(--color-primary-500-light);
      }
    }

    &:invalid {
      background-image: none;

      &::before {
        border-color: var(--color-red-500-light);
      }
    }

    &:checked {
      &::before {
        background-color: var(--color-primary-500-light);
        background-image: url('data:image/svg+xml; utf8, <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <path fill-rule="evenodd" clip-rule="evenodd" d="M20.7399 6.32733C21.1115 6.73599 21.0813 7.36844 20.6727 7.73995L9.67268 17.7399C9.29126 18.0867 8.70876 18.0867 8.32733 17.7399L3.32733 13.1945C2.91868 12.823 2.88856 12.1905 3.26007 11.7819C3.63157 11.3732 4.26402 11.3431 4.67268 11.7146L9.00001 15.6485L19.3273 6.26007C19.736 5.88856 20.3684 5.91868 20.7399 6.32733Z" fill="white"/> </svg>');
      }

      &:disabled {
        border: 0;
        background: rgba(0,0,0,0);

        &::before {
          border: 1px solid var(--color-neutral-300-light);
          background-color: var(--color-neutral-100-light);
          background-image: url('data:image/svg+xml; utf8, <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <path fill-rule="evenodd" clip-rule="evenodd" d="M20.7399 6.32733C21.1115 6.73599 21.0813 7.36844 20.6727 7.73995L9.67268 17.7399C9.29126 18.0867 8.70876 18.0867 8.32733 17.7399L3.32733 13.1945C2.91868 12.823 2.88856 12.1905 3.26007 11.7819C3.63157 11.3732 4.26402 11.3431 4.67268 11.7146L9.00001 15.6485L19.3273 6.26007C19.736 5.88856 20.3684 5.91868 20.7399 6.32733Z" fill="#{rgba(map.get(colors.$colors, 'neutral-400', 'light'), .99)}"/> </svg>');
        }
      }
    }


    &:disabled {
      border: 0;
      background: rgba(0,0,0,0);
    }

    .tmt-large & {
      padding: 0;
      border: 0;
    }
  }

  &[type="radio"] {
    &::before {
      border-radius: 50%;
    }

    &:checked {
      &::before {
        background-color: var(--color-primary-500-light);
        background-image: radial-gradient(var(--color-primary-500-light) 0, var(--color-primary-500-light) 48%, white 48%);
      }

      &:disabled {
        border: 0;
        background: rgba(0,0,0,0);

        &::before {
          border: 1px solid var(--color-neutral-300-light);
          background-color: var(--color-neutral-100-light);
          background-image: radial-gradient(var(--color-neutral-400-light) 0, var(--color-neutral-400-light) 48%, white 48%);
        }
      }
    }
  }

  &:disabled {
    border: 1px solid var(--color-neutral-300-light);
    background-color: var(--color-neutral-100-light);
    color: var(--color-neutral-500-light);
    cursor: not-allowed;
  }

  .tmt-large & {
    @include responsive-type("baseline", "large");
    padding: var(--space-xxs-large) var(--space-sm-large);
  }
}


.Input {
  position: relative;

  &--number {
    display: inline-block;
  }

  &__button {
    position: absolute;
    background-color: rgba(0,0,0,0);
    z-index: 1;
    border: 0;
    width: $input-icon-size;
    height: $input-icon-size;
    background-size: cover;
    background-repeat: no-repeat;

    .tmt-large & {
      width: $input-icon-size-large;
      height: $input-icon-size-large;
    }

    &--decrement {
      top: var(--space-xxs);
      left: var(--space-xxs);
      background-image: url('data:image/svg+xml; utf8, <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <path fill-rule="evenodd" clip-rule="evenodd" d="M2 12C2 11.4477 2.44772 11 3 11H21C21.5523 11 22 11.4477 22 12C22 12.5523 21.5523 13 21 13H3C2.44772 13 2 12.5523 2 12Z" fill="#{rgba(map.get(colors.$colors, 'primary-500', 'light'), .99)}"/> </svg>');

      .tmt-large & {
        top: var(--space-xxs-large);
        left: var(--space-xxs-large);
      }

      input:disabled + &,
      input:disabled + .Input__button + & {
        cursor: not-allowed;
        background-image: url('data:image/svg+xml; utf8, <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <path fill-rule="evenodd" clip-rule="evenodd" d="M2 12C2 11.4477 2.44772 11 3 11H21C21.5523 11 22 11.4477 22 12C22 12.5523 21.5523 13 21 13H3C2.44772 13 2 12.5523 2 12Z" fill="#{rgba(map.get(colors.$colors, 'neutral-400', 'light'), .99)}"/> </svg>');
      }
    }

    &--increment {
      top: var(--space-xxs);
      right: var(--space-xxs);
      left: unset;
      background-image: url('data:image/svg+xml; utf8, <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <path fill-rule="evenodd" clip-rule="evenodd" d="M12 2C12.5523 2 13 2.44772 13 3V11H21C21.5523 11 22 11.4477 22 12C22 12.5523 21.5523 13 21 13H13V21C13 21.5523 12.5523 22 12 22C11.4477 22 11 21.5523 11 21V13H3C2.44772 13 2 12.5523 2 12C2 11.4477 2.44772 11 3 11H11V3C11 2.44772 11.4477 2 12 2Z" fill="#{rgba(map.get(colors.$colors, 'primary-500', 'light'), .99)}"/> </svg>');

      .tmt-large & {
        top: var(--space-xxs-large);
        right: var(--space-xxs-large);
      }

      input:disabled + &,
      input:disabled + .Input__button + & {
        cursor: not-allowed;
        background-image: url('data:image/svg+xml; utf8, <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <path fill-rule="evenodd" clip-rule="evenodd" d="M12 2C12.5523 2 13 2.44772 13 3V11H21C21.5523 11 22 11.4477 22 12C22 12.5523 21.5523 13 21 13H13V21C13 21.5523 12.5523 22 12 22C11.4477 22 11 21.5523 11 21V13H3C2.44772 13 2 12.5523 2 12C2 11.4477 2.44772 11 3 11H11V3C11 2.44772 11.4477 2 12 2Z" fill="#{rgba(map.get(colors.$colors, 'neutral-400', 'light'), .99)}"/> </svg>');
      }
    }
  }

  &--radio,
  &--checkbox {
    display: flex;

    span {
      order: 2;
      @include responsive-type("baseline");
      color: var(--color-neutral-1000-light);
      margin-bottom: 0;
      margin-left: var(--space-xxs);
      transform: translateY(2px); // Font metrics

      .tmt-large & {
        @include responsive-type("baseline", "large");
        margin-left: var(--space-xxs-large);
      }
    }
  }
}

input[type="search" i]::-webkit-search-cancel-button {
  display: none;
}

.close-icon {
  display: none;
}

input[type="search"]:not(:placeholder-shown) ~ .close-icon {
  -webkit-appearance: none;
  border: none;
  background: none;
  appearance: none;
  position: absolute;
  display: block;
  z-index: 1;
  top: 9px;
  right: 19px;
  height: 24px;
  width: 22px;
  cursor: pointer;
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21ZM8.70711 7.29289C8.31658 6.90237 7.68342 6.90237 7.29289 7.29289C6.90237 7.68342 6.90237 8.31658 7.29289 8.70711L10.5858 12L7.29289 15.2929C6.90237 15.6834 6.90237 16.3166 7.29289 16.7071C7.68342 17.0976 8.31658 17.0976 8.70711 16.7071L12 13.4142L15.2929 16.7071C15.6834 17.0976 16.3166 17.0976 16.7071 16.7071C17.0976 16.3166 17.0976 15.6834 16.7071 15.2929L13.4142 12L16.7071 8.70711C17.0976 8.31658 17.0976 7.68342 16.7071 7.29289C16.3166 6.90237 15.6834 6.90237 15.2929 7.29289L12 10.5858L8.70711 7.29289Z' fill='%23B67A3E'/%3E%3C/svg%3E%0A");
}

.remove-padding {
  padding: 0;
  border-bottom: 1px solid var(--color-neutral-500-light);
}

.PageHeader {
  display: flex;
  align-items: flex-end;
  @include media-breakpoint-down(md) {
    flex-direction: column;
  }

  .Carousel__pagination {
    display: block;
  }
}

.bgColorLightGray {
  background-color: var(--color-neutral-100-light);
}

.PageHeader.centered {
  justify-content: center;
  align-items: center !important;
}

.PageHeader--carousel.centered {
  .PageHeader__body {
    padding: 310px 0 136px 0;
  }
}

.PageHeader {
  .Card {
    height: inherit;
    .Card__image {
      height: inherit;
      img {
        object-fit: cover;
        width: 100%;
        height: inherit;
      }
      &::after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 0.64) 100%
        );
      }
    }
  }
  .Carousel {
    .CardBody {
      position: absolute;
      bottom: 104px;
      left: 50%;
      align-items: center;
      transform: translate(-50%, -50%);
      .CardTitle {
        color: var(--color-white-light);
      }
    }
  }
}

.PageHeader__body.LeftAlign {
  margin-bottom: 88px;
}

.PageHeader.homeHero {
  height: 700px;
  @include media-breakpoint-down(md) {
    height: 575px;
  }
  .Carousel {
    &__nav-pagination {
      position: absolute;
      left: 50%;
      transform: translate(-50%);
      bottom: 48px;
      z-index: 10;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      white-space: nowrap;
    }
    &__nav-next,
    &__nav-prev {
      position: relative;
      .arrow {
        color: var(--color-white-light);
      }
    }
    &__pagination, &__nav-prev, &__nav-next {
      bottom: 0!important;
    }
  }
}

.header-title {
  .PageHeader {
    &__cover {
      &::after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: linear-gradient(
          180deg,
          rgba(246, 245, 243, 0.1) 0%,
          #f6f5f3 100%
        );
      }
    }
    &__body {
      .Card {
        .CardBody {
          padding: 136px 0 64px 0;
          .CardTitle {
            &__title {
              color: var(--color-neutral-1000-light);
            }
          }
        }
      }
    }
  }
}

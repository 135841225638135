.SvgIcon {
  max-width: var(--svg-size);
  max-height: var(--svg-size);

  &--large,
  .large & {
    max-width: var(--svg-size, 28px);
    max-height: var(--svg-size, 28px);
  }

  &--custom {
    max-width: var(--svg-width);
    max-height: var(--svg-height);
  }
}

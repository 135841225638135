@import "title";
@import "elements";

.Card {
  position: relative;
  display: flex;
  flex-direction: column;
  border: 1px solid var(--color-neutral-300-light);
  border-radius: var(--border-radius-8);

  &__image {
    position: relative;

    img {
      max-width: 100%;
    }
  }

  &--text-on-image {
    &::after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: var(--backdrop-gradient-light);
    }

    .CardBody {
      position: absolute;
      color: var(--color-neutral-0-light);
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 2;
    }
  }

  &--text-on-image {
    .CardTitle {
      color: var(--color-neutral-0-light);
    }
  }

  &--horizontal {
    flex-direction: row;
    @include media-breakpoint-down(md) {
      flex-direction: column;
    }

    .Card__image {
      order: 2;
      margin: var(--space-sm);
      max-width: 300px;
      flex-grow: 1;
      @include media-breakpoint-down(md) {
        order: 1;
        width: auto;
      }
    }
    .CardBody {
      flex-grow: 1;
    }
  }

  &--profile {
    text-align: center;

    .CardBody {
      align-items: center;
    }

    .Card__image {
      margin: var(--space-md);
      border-radius: 50%;
      overflow: hidden;
    }
  }
}

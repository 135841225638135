.datepicker-dropdown {
  width: 100%;
  .datepicker-view {
    width: 100%;
  }
}

.datepicker-dropdown .datepicker-picker {
  box-shadow: var(--shadow-shadow-24-light);
  border: 1px solid var(--color-neutral-300-light);
}

.datepicker-header {
  border-bottom: 1px solid var(--color-neutral-300-light);
  .datepicker-controls {
    .prev-button {
        &:after {
          content: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12.1295 18.6839C11.7518 19.0868 11.119 19.1073 10.7161 18.7295L4.31606 12.7295C4.11441 12.5405 4 12.2764 4 12C4 11.7236 4.11441 11.4595 4.31606 11.2705L10.7161 5.27046C11.119 4.89273 11.7518 4.91314 12.1295 5.31606C12.5073 5.71897 12.4869 6.3518 12.0839 6.72953L7.52878 11L21 11C21.5523 11 22 11.4477 22 12C22 12.5523 21.5523 13 21 13L7.52878 13L12.0839 17.2705C12.4869 17.6482 12.5073 18.281 12.1295 18.6839Z' fill='currentColor'/%3E%3C/svg%3E");
          padding-top: 5px;
        }
      }
      .next-button {
        &:after {
          content: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.8705 5.31606C12.2482 4.91315 12.881 4.89274 13.2839 5.27047L19.6839 11.2705C19.8856 11.4595 20 11.7236 20 12C20 12.2764 19.8856 12.5405 19.6839 12.7295L13.2839 18.7295C12.881 19.1073 12.2482 19.0869 11.8705 18.6839C11.4927 18.281 11.5131 17.6482 11.9161 17.2705L16.4712 13L3 13C2.44772 13 2 12.5523 2 12C2 11.4477 2.44772 11 3 11L16.4712 11L11.9161 6.72954C11.5131 6.35181 11.4927 5.71897 11.8705 5.31606Z' fill='currentColor'/%3E%3C/svg%3E%0A");
          padding-top: 5px;
        }
      }
  }
}

.datepicker {
  .days-of-week {
    span {
      color: var(--color-neutral-500-light);
      font-weight: normal;
    }
  }
  .prev,
  .next {
    color: var(--color-neutral-500-light) !important;
  }
}


.datepicker-controls .button {
  background: none;
  border: none;
  font-size: 16px !important;
  line-height: 24px;
  font-weight: bold;
}

.datepicker-footer {
  box-shadow: none;
  background-color: transparent;
  border-top: 1px solid var(--color-neutral-300-light);
}

.datepicker-cell.selected {
  background-color: var(--color-primary-500-light);
}

.datepicker-cell:not(.disabled, .prev, .next, .selected):hover {
  background-color: transparent;
  font-weight: normal;
  border: 1px solid var(--color-primary-500-light);
  border-radius: 4px;
  background-color: transparent;
}

.datepicker-cell:not(.day) {
  height: 2.5rem;
  margin: 1rem 0 1rem 0;
}

.datepicker-cell.range,
.datepicker-cell.range-end:not(.selected),
.datepicker-cell.range-start:not(.selected) {
  background-color: var(--color-primary-100-light);
  color: var(--color-neutral-1000-light);
}

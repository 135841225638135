.filters-container {
  margin: 0 0 64px 0;
  @include media-breakpoint-down(md) {
    margin: 0 12px 32px 0;
  }

  .filters {
    width: 100%;

    .mobile-btn {
      display: none;
      appearance: none;
      border: none;
      background-color: transparent;
    }
    #dropdown-options {
      display: flex;
      align-items: center;
      flex-grow: 1;
      margin: 0 28px;
      width: 100%;
    }
    label {
      margin-right: var(--space-md);
      display: flex;
    }
    @include media-breakpoint-down(md) {
      .mobile-btn {
        display: block;
        margin-top: var(--space-sm);
      }
      flex-direction: column;
    }
    #dropdown-options {
      @include media-breakpoint-down(md) {
        display: none;
        >*:not(:last-of-type) {
          margin-bottom: 8px;
        }
      }
    }
    .choices {
      width: 100%;
      &:not(:last-child) {
        margin-right: var(--space-sm);
      }
    }

    .choices-search,
    .date-wrapper {
      width: 100%;
      &:not(:last-child) {
        margin-right: var(--space-sm);
      }
    }

    .choices-search {
      position: relative;
      @include media-breakpoint-down(md) {
        display: none;
      }
    }

    .choices-search-mob {
      position: relative;
      width: 100%;
      @include media-breakpoint-up(md) {
        display: none;
      }
    }

    .date-wrapper {
      position: relative;

      .datepicker-input {
        cursor: pointer;
      }
      &::after {
        content: "";
        filter: invert(58%) sepia(83%) saturate(0%) hue-rotate(294deg)
          brightness(94%) contrast(87%);
        position: absolute;
        background: url('data:image/svg+xml; utf8, <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M12.354 5.354a.5.5 0 00-.708 0L7.853 9.146a.5.5 0 00.354.854h7.586a.5.5 0 00.353-.854l-3.793-3.792zm0 13.292a.5.5 0 01-.708 0l-3.793-3.792A.5.5 0 018.207 14h7.586a.5.5 0 01.353.854l-3.793 3.792z" fill="currentColor"/></svg>');
        background-position: center center;
        border: none;
        width: 24px;
        height: 24px;
        right: var(--space-xxs);
        top: 50%;
        transform: translateY(-50%);
        margin-top: 0px;
      }
    }

    @include media-breakpoint-down(md) {
      label {
        margin-top: 16px;
        margin-left: 2px;
        margin-right: 0;
      }
      .active {
        display: block !important;
      }
    }

    .search-input {
      margin-right: var(--space-sm);
    }

    .non-pt-filters {
      .choices {
        flex-grow: 1;
      }
    }
  }
}

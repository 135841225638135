#cd-timeline {
  position: relative;

  .more {
    display: none;
  }
}
#cd-timeline::before {
  content: "";
  position: absolute;
  top: 10px;
  left: 60px;
  height: 100%;
  width: 1px;
  padding-top: 5px;
  background: var(--color-neutral-300-light);
  @include media-breakpoint-up(lg) {
    left: 50%;
    top: 30px;
}
}

.cd-timeline-block {

  .cd-timeline-circle {
    display: block;
    position: absolute;
    width: 32px;
    height: 32px;
    border: 1px solid var(--color-neutral-300-light);
    border-radius: 50%;
    background: var(--color-white-light);
    left: 45px;
  }

  @include media-breakpoint-up(lg) {
    .cd-timeline-block {
      position: relative;
    }
    .cd-timeline-circle {
      margin-top: 15px;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
@include media-breakpoint-down(lg) {
  .cd-timeline-block {
    width: 65%;
    margin-left: auto;
  }
}
.cd-timeline-block:after {
  content: "";
  display: table;
  clear: both;
}
.cd-timeline-block:first-child {
  margin-top: 0;
}
.cd-timeline-block:last-child {
  margin-bottom: 0;
}
@include media-breakpoint-up(lg) {
  .cd-timeline-block:first-child {
    margin-top: 0;
  }
  .cd-timeline-block:last-child {
    margin-bottom: 0;
  }
}

.cd-timeline-img {
  position: absolute;
  top: 10px;
  left: 30px;
  width: 64px;
  height: 64px;
  border: 1px solid #e0e0e0;
  border-radius: 50%;
  background: var(--color-primary-500-light);
}

@include media-breakpoint-up(lg) {
  .cd-timeline-img {
    left: 50%;
    top: 30px;
    margin-left: -31px;
    /* Force Hardware Acceleration in WebKit */
    -webkit-transform: translateZ(0);
    -webkit-backface-visibility: hidden;
  }
}

.cd-timeline-content {
  .Card,
  h2 {
    margin-bottom: var(--space-md);
  }
  h2 {
    font-weight: 600;
    text-align: right;
    @include media-breakpoint-down(lg) {
      text-align: left;
    }
  }
  .cd-timeline-titles {
    @include responsive-type("lg", "default", true);
  }
}

@include media-breakpoint-up(lg) {
  .cd-timeline-content {
    margin-left: 0;
    padding: 1.6em;
    width: 29%;
    margin: 0 15%;
  }
  .cd-timeline-content::before {
    top: 24px;
    left: 100%;
    border-color: transparent;
    border-left-color: #333c42;
  }
  .cd-timeline-content .cd-date {
    position: relative;
    width: 100%;
    top: 6px;
    font-size: 16px;
    font-size: 1rem;
  }
  #cd-timeline:nth-child(even) .cd-timeline-content {
    float: right;

    h2 {
      text-align: left;
    }
  }
  #cd-timeline:nth-child(even) .cd-timeline-content::before {
    top: 24px;
    left: auto;
    right: 100%;
    border-color: transparent;
    border-right-color: #333c42;
  }
  #cd-timeline:nth-child(even) .cd-timeline-content .cd-read-more {
    float: right;
  }
  #cd-timeline:nth-child(even) .cd-timeline-content .cd-date {
    left: auto;
    right: 122%;
    text-align: right;
  }
}

.timeline-pattern {
  background: url("/images/efa-timeline-pattern.png") repeat;
}

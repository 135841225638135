#defaultImage {
    margin: 0 0 5px 0;
 }
 .onClickImage {
    height: 80px;
    width: 80px;
    cursor: pointer;
    margin: 0 var(--space-xxs) var(--space-xxs) var(--space-xxs);
    padding: 0;
 }
 .gallery-values {
    margin-left: 149px;
 }

 @include media-breakpoint-down(md) {
    .gallery-values {
        margin-left: 16px;
    }
}

 @include media-breakpoint-down(sm) {
    .pt-artwork-gallery {
        flex-direction: column;
    }
    .gallery-values {
        margin-top: 36px;
        margin-left: 0;
    }
}
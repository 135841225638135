.footer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--color-neutral-100-light);
  border-top: 1px solid var(--color-neutral-500-light);
  gap: var(--space-md);
  padding: var(--space-xl) 0;

  .footer-links-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--space-tiny);
    width: 100%;

    .footer-links {
      display: flex;
      flex-wrap:wrap;
      justify-content:center;
      margin-left: var(--space-ms);
      margin-right: var(--space-ms);
      a {
        margin-right: var(--space-ms);
        &:last-child {
          margin-right:0;
        }
      }
    }

    .footer-copyright {
      padding-top: var(--space-sm);
      @include media-breakpoint-up(md) {
        padding-top:0;
      }
    }
  }

  .responsive-footer-img {
    width: 100%;
    max-width: 350px;
  }

  .responsive-footer-img2 {
    width: 100%;
    max-width: 340px;
  }
  .espa-banner {
    position: fixed;
    bottom: 0;
    right: -1px;
    width: 340px;
    z-index: 10;
    opacity: 1;
    -webkit-transition: opacity 1s ease-in-out;
    -o-transition: opacity 1s ease-in-out;
    transition: opacity 1s ease-in-out;
    @include media-breakpoint-down(lg) {
      .espa-banner {
        width: 250px;
      }
    }
    @include media-breakpoint-down(md) {
      .espa-banner {
        width: 200px;
      }
    }
    img {
      max-width: 100%;
      display: block;
    }
  }
  .espa-banner.hide-banner {
    opacity: 0;
  }
}

@use "sass:map";

$colors: (
  primary-100: (
    light: #EBE1DB,
    dark: #002E66
  ),
  primary-500: (
    light: #823303,
    dark: #1F8BFF
  ),
  primary-700: (
    light: #451B01,
    dark: #85C0FF
  ),
  primary-900: (
    light: #291000,
    dark: #F3FBFF
  ),
  neutral-0: (
    light: #ffffff,
    dark: #000000
  ),
  neutral-100: (
    light: #F6F5F3,
    dark: #212121
  ),
  neutral-200: (
    light: #EEECE8,
    dark: #424242
  ),
  neutral-300: (
    light: #E5E2DC,
    dark: #616161
  ),
  neutral-400: (
    light: #DCD8D0,
    dark: #757575
  ),
  neutral-500: (
    light: #A59A86,
    dark: #9e9e9e
  ),
  neutral-600: (
    light: #757575,
    dark: #bdbdbd
  ),
  neutral-700: (
    light: #616161,
    dark: #e0e0e0
  ),
  neutral-800: (
    light: #424242,
    dark: #eeeeee
  ),
  neutral-900: (
    light: #212121,
    dark: #f5f5f5
  ),
  neutral-1000: (
    light: #000000,
    dark: #ffffff
  ),
  white: (
    light: #ffffff,
    dark: #ffffff
  ),
  black: (
    light: #000000,
    dark: #000000
  ),
  green-100: (
    light: #ecffed,
    dark: #173e1d
  ),
  green-500: (
    light: #009d06,
    dark: #32d74b
  ),
  orange-100: (
    light: #fff3e5,
    dark: #463316
  ),
  orange-500: (
    light: #ec7f00,
    dark: #ff9f0a
  ),
  red-100: (
    light: #fff2f2,
    dark: #4e1713
  ),
  red-500: (
    light: #d20000,
    dark: #ff453a
  ),
  red-700: (
    light: #8c0000,
    dark: #ff7a72
  ),
  red-800: (
    light: #5a0000,
    dark: #ffb9b5
  )
);

$backdrops: (
  black-48: (
    light: rgba(0, 0, 0, 0.48),
    dark: rgba(0, 0, 0, 0.64)
  ),
  black-64: (
    light: rgba(0, 0, 0, 0.64),
    dark: rgba(0, 0, 0, 0.80)
  ),
  black-80: (
    light: rgba(0, 0, 0, 0.80),
    dark: rgba(0, 0, 0, 0.96)
  ),
  black-90: (
    light: rgba(0, 0, 0, 0.90),
    dark: rgba(0, 0, 0, 0.90)
  ),
  white-48: (
    light: rgba(1, 1, 1, 0.48),
    dark: rgba(1, 1, 1, 0.64)
  ),
  white-64: (
    light: rgba(1, 1, 1, 0.64),
    dark: rgba(1, 1, 1, 0.80)
  ),
  white-80: (
    light: rgba(1, 1, 1, 0.80),
    dark: rgba(1, 1, 1, 0.96)
  ),
  white-90: (
    light: rgba(1, 1, 1, 0.90),
    dark: rgba(1, 1, 1, 0.90)
  ),
  gradient: (
    light: linear-gradient(0deg, rgba(0, 0, 0, 0.64) 0%, rgba(0, 0, 0, 0.16) 83.59%),
    dark: linear-gradient(0deg, rgba(0, 0, 0, 0.72) 0%, rgba(0, 0, 0, 0.24) 83.59%)
  )
);

$shadows: (
  shadow-16: (
    light: 0px 4px 16px rgba(0, 0, 0, 0.16),
    dark: 0px 4px 16px rgba(0, 0, 0, 0.48)
  ),
  shadow-24: (
    light: 0px 4px 24px rgba(0, 0, 0, 0.16),
    dark: 0px 4px 24px rgba(0, 0, 0, 0.48)
  ),
  shadow-32: (
    light: 0px 8px 32px rgba(0, 0, 0, 0.16),
    dark: 0px 8px 32px rgba(0, 0, 0, 0.48)
  ),
  shadow-64: (
    light: 0px 8px 64px rgba(0, 0, 0, 0.16),
    dark: 0px 8px 64px rgba(0, 0, 0, 0.48)
  )
);

$bold-shadows: (
  bold-shadow-16: (
    light: 0px 4px 16px rgba(0, 0, 0, 0.32),
    dark: 0px 4px 16px rgba(0, 0, 0, 0.32)
  ),
  bold-shadow-24: (
    light: 0px 4px 24px rgba(0, 0, 0, 0.32),
    dark: 0px 4px 24px rgba(0, 0, 0, 0.72)
  ),
  bold-shadow-32: (
    light: 0px 8px 32px rgba(0, 0, 0, 0.32),
    dark: 0px 8px 32px rgba(0, 0, 0, 0.72)
  ),
  bold-shadow-64: (
    light: 0px 8px 64px rgba(0, 0, 0, 0.32),
    dark: 0px 8px 64px rgba(0, 0, 0, 0.72)
  )
);

$background-blurs: (
  background-blur-8: blur(4px),
  background-blur-12: blur(6px),
  background-blur-16: blur(8px),
  background-blur-24: blur(8px),
);

:root {
  @each $prop, $value in $colors {
    --color-#{"" + $prop}-light: #{map.get($value, light)};
    --color-#{"" + $prop}-dark: #{map.get($value, dark)};
  }

  @each $prop, $value in $backdrops {
    --backdrop-#{"" + $prop}-light: #{map.get($value, light)};
    --backdrop-#{"" + $prop}-dark: #{map.get($value, dark)};
  }

  @each $prop, $value in $shadows {
    --shadow-#{"" + $prop}-light: #{map.get($value, light)};
    --shadow-#{"" + $prop}-dark: #{map.get($value, dark)};
  }

  @each $prop, $value in $bold-shadows {
    --bold-shadow-#{"" + $prop}-light: #{map.get($value, light)};
    --bold-shadow-#{"" + $prop}-dark: #{map.get($value, dark)};
  }
}

// Create utility classes
@each $prop, $value in $colors {
  .color-#{"" + $prop}-light {
    color: #{map.get($value, light)} !important;
  }

  .color-#{"" + $prop}-dark {
    color: #{map.get($value, dark)} !important;
  }

  .bg-#{"" + $prop}-light {
    background-color: #{map.get($value, light)} !important;
  }

  .bg-#{"" + $prop}-dark {
    background-color: #{map.get($value, dark)} !important;
  }
}

@each $prop, $value in $backdrops {
  .backdrop-#{"" + $prop}-light {
    background: #{map.get($value, light)} !important;
  }

  .backdrop-#{"" + $prop}-dark {
    background: #{map.get($value, dark)} !important;
  }
}

@each $prop, $value in $shadows {
  .shadow-#{"" + $prop}-light {
    background: #{map.get($value, light)} !important;
  }

  .shadow-#{"" + $prop}-dark {
    background: #{map.get($value, dark)} !important;
  }
}

@each $prop, $value in $bold-shadows {
  .bold-shadow-#{"" + $prop}-light {
    background: #{map.get($value, light)} !important;
  }

  .bold-shadow-#{"" + $prop}-dark {
    background: #{map.get($value, dark)} !important;
  }
}

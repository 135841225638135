.block-banner {
  padding-top: 16px;
  padding-bottom: 16px;
  .custom-reverse-row {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: var(--space-md) 0;

    @include media-breakpoint-down(md) {
      flex-direction: column;
      background-color: var(--color-neutral-200-light) !important;
    }

    .custom-reverse-image {
      width: 37%;

      @include media-breakpoint-down(md) {
        width: 100%;
      }

      img {
        width: 100%;
        aspect-ratio: 1/1;
        border-radius: var(--space-md);
        object-fit: cover;
        @include media-breakpoint-down(md) {
          border-radius: 0;
        }
      }
    }

    .custom-reverse-image.circle-border-radius {
      img {
        border-radius: 50%;
        @include media-breakpoint-down(md) {
          border-radius: 0;
        }
      }
    }

    .Card {
      width: 56%;
      border: none;
      justify-content: center;

      @include media-breakpoint-down(md) {
        margin-top: var(--space-lg);
      }

      .CardBody {
        align-items: center;
        text-align: center;

        & > * {
          margin-bottom: var(--space-md);
        }

        .CardTitle {
          .CardTitle__title {
            font-size: 40px;
            line-height: 48px;
            @include media-breakpoint-down(md) {
              font-size: 32px;
              line-height: 40px;
            }
          }
        }
      }
    }
  }

  .custom-reverse-row.reverse {
    .custom-reverse-image {
      order: 2;
      @include media-breakpoint-down(md) {
        order: 1;
      }
    }
    .Card {
      @include media-breakpoint-down(md) {
        order: 2;
      }
    }
  }
}
@import 'vendor/main';
@import 'variables/main';
@import 'global';




@import "C:/WORK/WORDPRESS Projects/DigitalIoannina/wordpress/wp-content/themes/digitalimitemplate/src/components/accordion/_index.scss";
@import "C:/WORK/WORDPRESS Projects/DigitalIoannina/wordpress/wp-content/themes/digitalimitemplate/src/components/aspect-ratio-box/_index.scss";
@import "C:/WORK/WORDPRESS Projects/DigitalIoannina/wordpress/wp-content/themes/digitalimitemplate/src/components/banners/_index.scss";
@import "C:/WORK/WORDPRESS Projects/DigitalIoannina/wordpress/wp-content/themes/digitalimitemplate/src/components/block-banner/_index.scss";
@import "C:/WORK/WORDPRESS Projects/DigitalIoannina/wordpress/wp-content/themes/digitalimitemplate/src/components/block-heading/_index.scss";
@import "C:/WORK/WORDPRESS Projects/DigitalIoannina/wordpress/wp-content/themes/digitalimitemplate/src/components/button/_index.scss";
@import "C:/WORK/WORDPRESS Projects/DigitalIoannina/wordpress/wp-content/themes/digitalimitemplate/src/components/card/_index.scss";
@import "C:/WORK/WORDPRESS Projects/DigitalIoannina/wordpress/wp-content/themes/digitalimitemplate/src/components/carousel/_index.scss";
@import "C:/WORK/WORDPRESS Projects/DigitalIoannina/wordpress/wp-content/themes/digitalimitemplate/src/components/chip/_index.scss";
@import "C:/WORK/WORDPRESS Projects/DigitalIoannina/wordpress/wp-content/themes/digitalimitemplate/src/components/contact-info/_index.scss";
@import "C:/WORK/WORDPRESS Projects/DigitalIoannina/wordpress/wp-content/themes/digitalimitemplate/src/components/content-block/_index.scss";
@import "C:/WORK/WORDPRESS Projects/DigitalIoannina/wordpress/wp-content/themes/digitalimitemplate/src/components/control/_index.scss";
@import "C:/WORK/WORDPRESS Projects/DigitalIoannina/wordpress/wp-content/themes/digitalimitemplate/src/components/custom-ergo-block/_index.scss";
@import "C:/WORK/WORDPRESS Projects/DigitalIoannina/wordpress/wp-content/themes/digitalimitemplate/src/components/custom-map-block/_index.scss";
@import "C:/WORK/WORDPRESS Projects/DigitalIoannina/wordpress/wp-content/themes/digitalimitemplate/src/components/date-picker/_index.scss";
@import "C:/WORK/WORDPRESS Projects/DigitalIoannina/wordpress/wp-content/themes/digitalimitemplate/src/components/dropdown/_index.scss";
@import "C:/WORK/WORDPRESS Projects/DigitalIoannina/wordpress/wp-content/themes/digitalimitemplate/src/components/fact/_index.scss";
@import "C:/WORK/WORDPRESS Projects/DigitalIoannina/wordpress/wp-content/themes/digitalimitemplate/src/components/feedback/_index.scss";
@import "C:/WORK/WORDPRESS Projects/DigitalIoannina/wordpress/wp-content/themes/digitalimitemplate/src/components/filter/_index.scss";
@import "C:/WORK/WORDPRESS Projects/DigitalIoannina/wordpress/wp-content/themes/digitalimitemplate/src/components/filters-block/_index.scss";
@import "C:/WORK/WORDPRESS Projects/DigitalIoannina/wordpress/wp-content/themes/digitalimitemplate/src/components/footer/_index.scss";
@import "C:/WORK/WORDPRESS Projects/DigitalIoannina/wordpress/wp-content/themes/digitalimitemplate/src/components/form/_index.scss";
@import "C:/WORK/WORDPRESS Projects/DigitalIoannina/wordpress/wp-content/themes/digitalimitemplate/src/components/gallery-block/_index.scss";
@import "C:/WORK/WORDPRESS Projects/DigitalIoannina/wordpress/wp-content/themes/digitalimitemplate/src/components/grey-box/_index.scss";
@import "C:/WORK/WORDPRESS Projects/DigitalIoannina/wordpress/wp-content/themes/digitalimitemplate/src/components/header-block/_index.scss";
@import "C:/WORK/WORDPRESS Projects/DigitalIoannina/wordpress/wp-content/themes/digitalimitemplate/src/components/hero/_index.scss";
@import "C:/WORK/WORDPRESS Projects/DigitalIoannina/wordpress/wp-content/themes/digitalimitemplate/src/components/horizontal-banner/_index.scss";
@import "C:/WORK/WORDPRESS Projects/DigitalIoannina/wordpress/wp-content/themes/digitalimitemplate/src/components/menu/_index.scss";
@import "C:/WORK/WORDPRESS Projects/DigitalIoannina/wordpress/wp-content/themes/digitalimitemplate/src/components/page-heading/_index.scss";
@import "C:/WORK/WORDPRESS Projects/DigitalIoannina/wordpress/wp-content/themes/digitalimitemplate/src/components/pagination-block/_index.scss";
@import "C:/WORK/WORDPRESS Projects/DigitalIoannina/wordpress/wp-content/themes/digitalimitemplate/src/components/pagination/_index.scss";
@import "C:/WORK/WORDPRESS Projects/DigitalIoannina/wordpress/wp-content/themes/digitalimitemplate/src/components/pt-advanced-search/_index.scss";
@import "C:/WORK/WORDPRESS Projects/DigitalIoannina/wordpress/wp-content/themes/digitalimitemplate/src/components/pt-artists-list/_index.scss";
@import "C:/WORK/WORDPRESS Projects/DigitalIoannina/wordpress/wp-content/themes/digitalimitemplate/src/components/pt-artwork-navigation/_index.scss";
@import "C:/WORK/WORDPRESS Projects/DigitalIoannina/wordpress/wp-content/themes/digitalimitemplate/src/components/pt-artwork-thumbnail/_index.scss";
@import "C:/WORK/WORDPRESS Projects/DigitalIoannina/wordpress/wp-content/themes/digitalimitemplate/src/components/pt-artwork/_index.scss";
@import "C:/WORK/WORDPRESS Projects/DigitalIoannina/wordpress/wp-content/themes/digitalimitemplate/src/components/pt-footer/_index.scss";
@import "C:/WORK/WORDPRESS Projects/DigitalIoannina/wordpress/wp-content/themes/digitalimitemplate/src/components/pt-menu-item/_index.scss";
@import "C:/WORK/WORDPRESS Projects/DigitalIoannina/wordpress/wp-content/themes/digitalimitemplate/src/components/range-picker/_index.scss";
@import "C:/WORK/WORDPRESS Projects/DigitalIoannina/wordpress/wp-content/themes/digitalimitemplate/src/components/rich-content-block/_index.scss";
@import "C:/WORK/WORDPRESS Projects/DigitalIoannina/wordpress/wp-content/themes/digitalimitemplate/src/components/rich-text/_index.scss";
@import "C:/WORK/WORDPRESS Projects/DigitalIoannina/wordpress/wp-content/themes/digitalimitemplate/src/components/svg-icon/_index.scss";
@import "C:/WORK/WORDPRESS Projects/DigitalIoannina/wordpress/wp-content/themes/digitalimitemplate/src/components/table/_index.scss";
@import "C:/WORK/WORDPRESS Projects/DigitalIoannina/wordpress/wp-content/themes/digitalimitemplate/src/components/timeline/_index.scss";
@import "C:/WORK/WORDPRESS Projects/DigitalIoannina/wordpress/wp-content/themes/digitalimitemplate/src/components/video-embed/_index.scss";

@import 'nouislider/dist/nouislider';

.alignwide {
  /* Set these margins to work with your own theme. */
  margin-left: -80px;
  margin-right: -80px;
  max-width: 100vw;
}

.alignfull {
  margin-left: calc(-100vw / 2 + 100% / 2);
  margin-right: calc(-100vw / 2 + 100% / 2);
  max-width: 100vw;
}

.alignfull img {
  width: 100vw;
}


@media (min-width: 1024px) {
  .lg\:invisible {
    visibility: hidden !important;
  }
}

.monuments_museums-template-default {
  .PageHeader {
    align-items: center !important;
  }
}

.espa-banner {
    position: fixed;
    bottom: 0;
    right: -1px;
    width: 340px;
    z-index: 10;
    opacity: 1;
    -webkit-transition: opacity 1s ease-in-out;
    -o-transition: opacity 1s ease-in-out;
    transition: opacity 1s ease-in-out;
    @include media-breakpoint-down(lg) {
      .espa-banner {
        width: 250px;
      }
    }
    @include media-breakpoint-down(md) {
      .espa-banner {
        width: 200px;
      }
    }
    img {
      max-width: 100%;
      display: block;
    }
  }
  .espa-banner.hide-banner {
    opacity: 0;
  }

  .mobile-menu-graphic{
    overflow:  hidden;
  }
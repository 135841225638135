.grey-box-container {
  flex-grow: 1;

  .grey-box-top {
    font-weight: 700;
    svg {
      margin-bottom: var(--space-xxs);
      opacity: 100% !important;
      margin-right: 0 !important;
    }
  }

  .grey-box {
    padding: var(--space-sm) 0;
    text-align: center;

    svg {
      opacity: 20%;
      margin-right: var(--space-xxs);
    }

    &:not(:last-child) {
      border-bottom: 1px solid rgba(38, 38, 38, 0.1);
    }
  }
}

.main {
  .grey-box-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.map-container {
  border-radius: 40px;
}

#desktop-menu {
  position: relative;
  display: flex;
  height: 88px;
  flex-direction: column;
  align-items: center;
  border-bottom: 1px solid var(--color-neutral-500-light);

  @include media-breakpoint-down(md) {
    display: none;
  }

  .header-logos {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: var(--space-xs) var(--space-md);
    z-index: 5;
    background-color: var(--color-white-light);

    .header-logo {
      width: 64px;
      height: 64px;
    }

    .main-header-logo {
      width: 100%;
      height: 100%;
      max-width: 518px;
    }
  }

  .menu-navigation {
    position: sticky;
    top: 0;
    width: auto;
    max-width: auto;
    display: inline-flex;
    justify-content: space-evenly;
    background-color: var(--color-white-light);
    border: 1px solid var(--color-neutral-500-light);
    border-top: none;
    border-bottom-left-radius: var(--border-radius-48);
    border-bottom-right-radius: var(--border-radius-48);
    padding: var(--space-ms) var(--space-sm) var(--space-ms) var(--space-sm);
    z-index: 2;

    .Button {
      &__label {
        color: var(--color-black-light);
      }
    }

    .menu-item-underline {
      cursor: pointer;
      display: inline-block;
      position: relative;
      font-weight: 600;
      padding: 0 var(--space-sm);
      &:after {
        content: "";
        position: absolute;
        width: calc(100% - 2 * var(--space-sm));
        transform: scaleX(0);
        height: 4px;
        bottom: -24px;
        left: var(--space-sm);
        background-color: var(--color-neutral-500-light);
        transition: transform 0.25s ease-out;
      }
      &:hover:after,
      &:focus:after,
      &:active:after {
        transform: scaleX(1);
      }
    }

    .menu-item-underline.active {
      cursor: pointer;
      display: inline-block;
      position: relative;
      &:after {
        content: "";
        position: absolute;
        width: 100%;
        transform: scaleX(1);
        height: 4px;
        bottom: -24px;
        left: 0;
      }
    }
  }
}

#mobile-menu {
  display: none;
  @include media-breakpoint-down(md) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .mobile-menu-navigation {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    position: fixed;
    z-index: 3;
    background-color: white;
  
    .mobile-menu-items {
      display: none;
      flex-direction: column;
      width: 100%;
      padding: 0 var(--space-sm) var(--space-xxs) var(--space-sm);

      .menu-item-underline {
        padding: var(--space-xxs) 0;
        align-self: flex-start;
        font-size: 24px;
        line-height: 32px;
      }

      .Button {
        align-self: flex-start;

        &__label {
          color: var(--color-black-light);
          font-weight: 400;
          font-size: 24px;
          line-height: 32px;
        }
      }
    }

    .mobile-logo-menu {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: var(--space-xs) var(--space-sm) var(--space-xs) var(--space-sm);

      button {
        color: var(--color-black-light)!important;
      }

      #menu-btn.active {
        // width: var(--space-md);
        // height: var(--space-md);
        // &::before {
        //   content: "";
        //   opacity: 1;
        //   width: 24px;
        //   height: var(--space-ms);
        //   margin-top: 5px;
        //   margin-left: 8px;
        //   background: none;
        //   background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M3.29289 3.29289C3.68342 2.90237 4.31658 2.90237 4.70711 3.29289L12 10.5858L19.2929 3.29289C19.6834 2.90237 20.3166 2.90237 20.7071 3.29289C21.0976 3.68342 21.0976 4.31658 20.7071 4.70711L13.4142 12L20.7071 19.2929C21.0976 19.6834 21.0976 20.3166 20.7071 20.7071C20.3166 21.0976 19.6834 21.0976 19.2929 20.7071L12 13.4142L4.70711 20.7071C4.31658 21.0976 3.68342 21.0976 3.29289 20.7071C2.90237 20.3166 2.90237 19.6834 3.29289 19.2929L10.5858 12L3.29289 4.70711C2.90237 4.31658 2.90237 3.68342 3.29289 3.29289Z' fill='black'/%3E%3C/svg%3E%0A");
        // }
        // svg {
        //   display: none!important;
        // }
      }
    }
  }
}

.main-mobile-logo {
  position: relative;
  top: 56px;
  margin-bottom: 72px;
  margin-top: var(--space-xs);
  padding: 0 var(--space-sm);

  .main-header-logo {
    width: 100%;
  }

  @include media-breakpoint-up(md) {
    display: none;
  }
}
.lg-actions .lg-next,
.lg-actions .lg-prev {
  position: relative;
  border: none;
  border-radius: 50%;
  width: var(--space-xl);
  height: var(--space-xl);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: var(--color-white-light);
  @include media-breakpoint-down(sm) {
    border-radius: unset;
    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12.1295 18.6839C11.7518 19.0868 11.119 19.1073 10.7161 18.7295L4.31606 12.7295C4.11441 12.5405 4 12.2764 4 12C4 11.7236 4.11441 11.4595 4.31606 11.2705L10.7161 5.27046C11.119 4.89273 11.7518 4.91314 12.1295 5.31606C12.5073 5.71897 12.4869 6.3518 12.0839 6.72953L7.52878 11L21 11C21.5523 11 22 11.4477 22 12C22 12.5523 21.5523 13 21 13L7.52878 13L12.0839 17.2705C12.4869 17.6482 12.5073 18.281 12.1295 18.6839Z' fill='white'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    margin-top: 10px;
    svg {
      display: none;
    }
  }

  @include media-breakpoint-up(sm) {
    background: var(--backdrop-black-64-light);
  }

  @include media-breakpoint-down(sm) {
    &.lg-next {
      transition: none;
      transform: rotate(180deg);
      transform-origin: 20% 0%;
    }
  }

  &.lg-next svg,
  &--inverted svg {
    transform: translateX(2px) rotate(180deg);
  }
  &:focus,
  &:hover {
    cursor: pointer;
    @include media-breakpoint-up(sm) {
      background: var(--backdrop-black-80-light);
    }
  }

  &:active {
    @include media-breakpoint-up(sm) {
      background: var(--color-black-light);
    }
  }

  &:disabled {
    cursor: not-allowed !important;
    background: var(--backdrop-black-48-light) !important;
  }
}

.Control {
  position: relative;
  border: none;
  border-radius: 50%;
  width: var(--space-xl);
  height: var(--space-xl);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: var(--color-white-light);
  background: var(--backdrop-black-64-light);

  &:focus,
  &:hover {
    cursor: pointer;
    background: var(--backdrop-black-80-light);
  }

  &:active {
    background: var(--color-black-light);
  }

  &:disabled {
    cursor: not-allowed !important;
    background: var(--backdrop-black-48-light) !important;
  }

  svg {
    transform: translateX(-2px);
  }

  &.lg-next svg,
  &--inverted svg {
    transform: translateX(2px) rotate(180deg);
  }

  &--player svg {
    transform: none;
  }
}

.lg-actions .lg-next,
.lg-actions .lg-prev {
  position: absolute;

  &::after,
  &::before {
    display: none;
  }
}

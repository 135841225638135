.expanding-menu.active {
  .contact-info {
    width: 100%;
    .addresses-info {
      display: flex;
      gap: 4px;
      .address1,
      .address2 {
        background: var(--color-neutral-1000-transparent-4-light);
        border-radius: 40px;
      }
    }
  }
}

.mega-mobile-menu.active {
  .addresses-info {
    display: flex;
    flex-direction: column;
    gap: 4px;
    .address1,
    .address2 {
      background: var(--color-neutral-1000-transparent-4-light);
      border-radius: 40px;
    }
  }
}

.contact-info {
  width: 100%;

  .addresses-info {
    display: flex;
  }
}
